import { motion } from "framer-motion";
import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

type AnimatedItemProps = {
  to: string;
  name: string;
  type: string;
};

const AnimatedMobileMenuItem = ({ to, name, type }: AnimatedItemProps) => {
  const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] };

  const menuVariants = {
    initial: {
      y: 50,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.li variants={menuVariants} transition={transition}>
      {type === "link" && (
        <Link to={to} activeClassName="active">
          {name}
        </Link>
      )}
      {type === "anchor" && <AnchorLink to={to} title={name} stripHash />}
    </motion.li>
  );
};

export default AnimatedMobileMenuItem;
