import React from "react";
import styled from "styled-components";
import Logo from "../assets/images/logo.svg";
import { Link } from "gatsby";
import NavMenu from "./NavMenu";

const HeaderStyles = styled.header`
  grid-column: 2 / span 12;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14vh;
  z-index: 10;

  @media ${(props) => props.theme.breakpoints.m} {
    height: 8rem;
    grid-column: 2 / span 6;
    margin: 0;
  }

  .logo {
    width: 80px;
  }
`;

const Header = () => {
  return (
    <HeaderStyles>
      <Link to="/" aria-label="Mihai Ursu Logo">
        <Logo className="logo" />
      </Link>
      <NavMenu />
    </HeaderStyles>
  );
};

export default Header;
