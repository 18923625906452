const menuLinks = [
  {
    to: "/",
    name: "Home",
    type: "link",
  },
  {
    to: "/#about-me",
    name: "About Me",
    type: "anchor",
  },
  {
    to: "/projects",
    name: "Projects",
    type: "link",
  },
  {
    to: "/contact",
    name: "Contact",
    type: "link",
  },
];

export default menuLinks;
