import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components";
import MobileHamburger from "./MobileHamburger";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import AnimatedMobileMenuItem from "./AnimatedMobileMenuItem";
import menuLinks from "../data/menuLinks";

const NavMenuStyles = styled.nav`
  .desktop {
    @media ${(props) => props.theme.breakpoints.m} {
      display: none;
    }
    a {
      text-transform: uppercase;
      font-weight: 500;
      color: var(--color-gray-600);
      font-size: var(--font-base-size);
      text-decoration: none;
      font-family: "Inter";
      margin-right: 1em;
      transition: color 300ms;
      padding: 1em 1em;
      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &.active {
        color: var(--color-black);
      }
    }
  }
  .mobile-menu {
    display: none;
    @media ${(props) => props.theme.breakpoints.m} {
      display: block;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
    }
    li {
      a {
        display: inline-block;
        color: var(--color-white);
        text-decoration: none;
        font-size: var(--font-title2-size);
        line-height: var(--font-title2-line-height);
        padding: 0.1rem;
        margin-bottom: 0.4em;
      }
    }
  }
  .mobile-overlay {
    display: none;
    @media ${(props) => props.theme.breakpoints.m} {
      display: block;
      background: var(--color-black);
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 8;
    }
  }
`;

const NavMenu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] };

  const parent = {
    animate: {
      transition: { staggerChildren: 0.2, delayChildren: 0.4 },
    },
  };

  return (
    <NavMenuStyles>
      <div className="desktop">
        <Link to="/" activeClassName="active">
          Home
        </Link>
        <AnchorLink to="/#about-me" title="About Me" stripHash />
        <Link to="/projects" activeClassName="active" partiallyActive={true}>
          Projects
        </Link>
        <Link to="/contact" activeClassName="active">
          Contact
        </Link>
      </div>

      <motion.div
        className="hamburger"
        initial={false}
        animate={isOpen ? "open" : "closed"}
      >
        <MobileHamburger isOpen={isOpen} toggle={() => toggleOpen()} />
      </motion.div>
      <div className="mobile-nav" onClick={() => toggleOpen()}>
        <AnimatePresence>
          {isOpen && (
            <>
              <motion.div exit={{ opacity: 0 }} className="mobile-menu">
                <motion.ul
                  variants={parent}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  {menuLinks.map((link, i) => (
                    <AnimatedMobileMenuItem
                      key={i}
                      to={link.to}
                      name={link.name}
                      type={link.type}
                    />
                  ))}
                </motion.ul>
              </motion.div>
              <motion.div
                exit={{ opacity: 0 }}
                style={{ originY: 0 }}
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 1 }}
                transition={transition}
                className="mobile-overlay"
              />
            </>
          )}
        </AnimatePresence>
      </div>
    </NavMenuStyles>
  );
};

export default NavMenu;
