import React from "react";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";
import Grid from "./Layout/Grid";
import Header from "./Header";

import { ThemeProvider } from "styled-components";
import Theme from "../styles/theme";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <GlobalStyles />
      <Typography />

      <ThemeProvider theme={Theme}>
        <Grid>
          <Header />
          {children}
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default Layout;
